import React from 'react'
import Layout from '../components/ui-components/containers/layout'
import TextContainer from '../components/ui-components/containers/text-container'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useStaticQuery } from 'gatsby'
import Button from '../components/ui-components/button'
import ContactForm from '../components/contact/contact-form'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import SEO from '../components/seo/SEO'

const Spenden = () => {
  const data = useStaticQuery(graphql`
    {
      imgBig: file(relativePath: { eq: "hero_img_placeholder.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      imgSmall: file(relativePath: { eq: "kind_freut_sich.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title={'Wie du helfen kannst'}
        description={
          'Erfahre hier, wie du mit deiner Spende benachteiligten Kindern helfen kannst'
        }
      />
      <TextContainer>
        <h2 className="mt-12 font-handwritten text-3xl">
          Zaubern Sie einem Kind ein Lächeln ins Gesicht!
        </h2>
        <p>
          Mit Ihrer Spende erfüllen Sie von Kinderarmut betroffenen Kindern ihre
          Weihnachtswünsche – und erhalten dafür das unvergleichbare Gefühl,
          einem kleinen Menschen <strong> Glück </strong> geschenkt zu haben.
        </p>
        <p className="pt-4">
          Für den geringen Betrag von 10€ pro Jahr(!) sorgen Sie nicht nur
          dafür, dass der Platz unter dem Tannenbaum eines Kindes nicht leer
          bleibt. Durch Ihre Herzenswärme erfährt das Kind viel Wesentlicheres:
          <strong> Herzlichkeit, Nächstenliebe, Mitgefühl. </strong>
        </p>
        <p className="py-4">
          Natürlich können Sie auch jederzeit einen höheren Betrag spenden. Es
          ist jedoch unsere Überzeugung, dass wirklich
          <strong> jeder </strong>
          nicht selbst von Armut betroffene oder bedrohte Mensch 10€ übrig hat.
          Das meinen wir nicht anmaßend – aber überlegen Sie sich einmal
          Folgendes: Jeder von uns gibt ein- oder mehrmals pro Jahr 10€ oder
          sogar mehr für Dinge aus, die ihn letzten Endes nicht glücklich
          machen. Braucht man wirklich das zehnte Paar Schuhe, das man doch nie
          anzieht und im Schrank stehen lässt?
        </p>
        <p>
          Mit einer 10€-Spende an Shine On tun sie jedoch zum einen
          <strong> garantiert </strong>
          etwas <strong> Sinnvolles </strong>: Sie bringen{' '}
          <strong>Freude </strong> in das Leben eines anderen Menschen – und zum
          anderen wird Sie das im Gegensatz zu einem zusätzlichen Paar Schuhe
          auch <strong>garantiert </strong> mit <strong>Glück </strong>
          erfüllen.
        </p>
      </TextContainer>

      <div className="bg-brand w-screen flex flex-col md:flex-row mt-12 h-auto md:h-76 pb-8 md:pb-0">
        <div className="w-full md:w-2/3 overflow-hidden">
          <Img
            fluid={data.imgSmall.childImageSharp.fluid}
            alt=""
            style={{ maxHeight: '600px' }}
          />
        </div>

        <div className="w-full md:w-1/3 flex pl-4 mt-4 flex-col pr-8 justify-between">
          <div>
            <h2 className="text-3xl font-handwritten pr-8 pt-4">
              Machen Sie jetzt mit
            </h2>
            <p className="pr-12 pt-4">
              Aktuell können Sie über unsere betterplace.me-Kampagne spenden:
            </p>
            <div className="w-full pl-2 pt-8 pb-8">
              <Button type="'secondary'">
                <OutboundLink
                  href="https://www.betterplace.me/weihnachtsgeschenke-fuer-benachteiligte-kinder"
                  className="w-full text-center"
                >
                  Zur Kampagne
                </OutboundLink>
              </Button>
            </div>
          </div>

          {/**
            <div className="flex flex-row mt-4">
              <div className="w-2/3 flex">
                <span className="flex items-center bg-gray-400 rounded rounded-r-none px-3 font-bold text-grey-darker">
                  €
                </span>
                <input
                  type="number"
                  name="price"
                  placeholder="25"
                  className="bg-grey-lighter text-gray-800 py-2 px-2 font-normal rounded border border-gray-400 rounded-l-none font-bold"
                />
              </div>
              
            </div>
             */}
        </div>
        {/** 
          <div className="pb-4 mt-24 md:mt-0">
            <hr className="border-black" />
            <div className="text-center pt-2">
              <h2 className="font-bold">Spendenkonto</h2>
              <span>
                Bank für Sozialwirtschaft AG, Stuttgart <br />
                IBAN: DEXX XXXX XXXX XXXX XXXX XX <br />
                BIC: XXXXXXXXXX <br />
              </span>
            </div>
          </div>
           */}
      </div>

      <ContactForm></ContactForm>
    </Layout>
  )
}

export default Spenden
